import { Controller } from "stimulus";

// see https://marcgrabanski.com/building-html-in-jquery-and-javascript
const buildHTML = function(tag, html, attrs) {
  // you can skip html param
  if (typeof html != 'string') {
    attrs = html
    html = null
  }
  var h = '<' + tag
  for (attr in attrs) {
    if (attrs[attr] === false) continue
    h += ' ' + attr + '="' + attrs[attr] + '"'
  }
  return (h += html ? '>' + html + '</' + tag + '>' : '/>')
}

export default class extends Controller {
  static targets = ['content']

  connect() {
    this.start_timer()
  }

  start_timer() {
    setTimeout(() => { this.contentTarget.innerHTML = ''; }, 5000)
  }
}
